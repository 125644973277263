import {WidgetFrostedDetailCard} from "../../organisms/widget--frosted-detail-card";

export function ParagraphFrostedDetailCard({paragraph, ...props}) {
  if (props?.textColor) delete (props.textColor);
  return (
    <WidgetFrostedDetailCard title={paragraph.field_fdc_title} image={paragraph?.field_fdc_image}
                             subtitle={paragraph?.field_fdc_subtitle} body={paragraph?.field_fdc_description?.processed}
                             contain={paragraph?.field_fdc_contain_image}
                             button={paragraph?.field_fdc_button} {...props} />
  )
}
